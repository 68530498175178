import React, { ChangeEvent } from 'react';
import {
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Profile } from '../Models/Profile';

interface Props {
	setTransferOverFtp: (value: boolean) => void;
	profile: Profile;
}

export const IbisticRadioButtons: React.FC<Props> = ({
	setTransferOverFtp,
	profile,
}) => {
	const { t } = useTranslation();
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value as 'ibistic' | 'email';
		newValue === 'ibistic'
			? setTransferOverFtp(true)
			: setTransferOverFtp(false);
	};

	if (!profile) return null;
	return (
		<FormControl component="fieldset">
			<RadioGroup
				name="expenseDestination"
				value={profile.transferOverFtp ? 'ibistic' : 'email'}
				onChange={handleChange}
			>
				<FormControlLabel
					value="ibistic"
					control={<Radio />}
					label={t('send_pdf_ibistic')}
				/>
				<FormControlLabel
					value="email"
					control={<Radio />}
					label={t('send_pdf_email')}
				/>
			</RadioGroup>
		</FormControl>
	);
};
